import {
  PORTION_FAMILY,
  PORTION_SINGLE,
  RECIPE_MIN_SINGLE,
  RECIPE_MIN_DEFAULT,
  RECIPE_MAX_FAMILY,
  RECIPE_MAX_DEFAULT,
  RECIPE_MIN_TWO_PEOPLE_ONBOARDING,
} from '@mindfulchefuk/constants'

import {
  PortionCount,
  RecipeMinimums,
  RecipeMaximums,
} from '@mindfulchefuk/config/portionCountConfig'

interface GetMinimumMaximumRecipesInterface {
  portionCount: PortionCount
  journey?: 'onboarding'
}

type GetMinimumMaximumReturnType = {
  minimumRecipes: RecipeMinimums
  maximumRecipes: RecipeMaximums
}

export const getMinimumMaximumRecipesForPortion = ({
  portionCount,
  journey,
}: GetMinimumMaximumRecipesInterface): GetMinimumMaximumReturnType => {
  if (portionCount === PORTION_FAMILY) {
    return {
      minimumRecipes: RECIPE_MIN_DEFAULT,
      maximumRecipes: RECIPE_MAX_FAMILY,
    }
  }

  if (portionCount === PORTION_SINGLE) {
    return {
      minimumRecipes: RECIPE_MIN_SINGLE,
      maximumRecipes: RECIPE_MAX_DEFAULT,
    }
  }

  return {
    minimumRecipes:
      journey === 'onboarding'
        ? RECIPE_MIN_TWO_PEOPLE_ONBOARDING
        : RECIPE_MIN_DEFAULT,
    maximumRecipes: RECIPE_MAX_DEFAULT,
  }
}
