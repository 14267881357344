import usePrevious from '@mindfulchefuk/hooks/usePrevious'
import { useEffect, useState } from 'react'

import { ProductWithQuantity } from '@mindfulchefuk/query'
import { RecipeWithQuantity } from '@mindfulchefuk/features/Recipes/interfaces'
import {
  mapProductToItem,
  mapRecipeToCartItem,
} from '@mindfulchefuk/features/Cart/components/transformers'
import { CartItemType } from '@mindfulchefuk/features/Cart/interfaces'

export const useNewestItem = (
  products?: ProductWithQuantity[],
  lastAddedRecipe?: RecipeWithQuantity
) => {
  const previousProducts = usePrevious(products)
  const [newest, setNewest] = useState(null)
  const [newestProduct, setNewestProduct] = useState(null)
  const [newestRecipe, setNewestRecipe] = useState(null)

  // Products
  useEffect(() => {
    if (!previousProducts || !products) {
      return
    }

    // Product removed
    if (products.length < previousProducts.length) {
      return setNewestProduct(null)
    }

    const addedProduct = products.find((currentProduct) => {
      const previousItem = previousProducts.find(
        (previous) => currentProduct.id === previous.id
      )
      return !previousItem || currentProduct.quantity > previousItem.quantity
    })

    if (addedProduct) {
      setNewestProduct(mapProductToItem(addedProduct))
      setNewest(CartItemType.PRODUCT)
    }
  }, [products, previousProducts])

  // Recipes
  useEffect(() => {
    // Recipe removed
    if (!lastAddedRecipe) {
      return setNewestRecipe(null)
    }

    if (
      lastAddedRecipe.id !== newestRecipe?.id ||
      lastAddedRecipe.quantity > newestRecipe.quantity
    ) {
      setNewestRecipe(
        mapRecipeToCartItem(lastAddedRecipe, { includeQuantity: true })
      )
      setNewest(CartItemType.RECIPE)
    }
  }, [lastAddedRecipe, newestRecipe])

  return newest === CartItemType.RECIPE ? newestRecipe : newestProduct
}
