import { Product, ProductWithQuantity } from '@mindfulchefuk/query'
import { DeliveryRecipe } from '@mindfulchefuk/features/Recipes/interfaces'
import { CartItem } from '@mindfulchefuk/features/Cart/interfaces'
import { MouseEventHandler } from 'react'
import { ProductContent } from '@mindfulchefuk/features/Products/interfaces'

interface MapRecipeOptions {
  onClick?: MouseEventHandler
  includeQuantity?: boolean
  includeFeedback?: boolean
  includePrice?: boolean
}

export const mapRecipeToCartItem = (
  recipe: DeliveryRecipe,
  {
    onClick,
    includeQuantity,
    includeFeedback,
    includePrice = true,
  }: MapRecipeOptions = {}
) => {
  const cartItem: CartItem = {
    id: recipe.id,
    price: Number(recipe.price),
    heading: recipe.title,
    image: recipe.imageUrl,
    quantity: recipe.quantity,
    type: 'recipes',
  }

  if (recipe.quantity > 1) {
    cartItem.price *= recipe.quantity
  }

  if (!includePrice) {
    cartItem.price = undefined
  }

  if (includeQuantity && recipe.quantity > 1) {
    cartItem.heading = `${recipe.quantity} × ${recipe.title}`
  }

  if (includeFeedback) {
    cartItem.feedback = recipe.feedback
  }

  if (onClick) {
    cartItem.onClick = onClick
  }

  return cartItem
}

export function mapProductToCartItem(
  product: ProductContent,
  products: Product[]
): CartItem {
  const cartProduct = products.find((item) => item.id === product.id)

  const productSummaryItem: CartItem = {
    id: product.id,
    price: Number(product.price),
    heading: cartProduct?.name,
    image: cartProduct?.imageUrl,
    quantity: product.quantity,
  }

  return productSummaryItem
}

export function mapProductToItem(
  product: ProductWithQuantity,
  options = { hasQuantityInHeading: true }
): CartItem {
  const { price, priceBeforeDiscount, name, quantity, imageUrl } = product
  const totalPrice = quantity > 1 ? price * quantity : price

  let totalPriceBeforeDiscount = priceBeforeDiscount
  if (priceBeforeDiscount && quantity > 1) {
    totalPriceBeforeDiscount = priceBeforeDiscount * product.quantity
  }

  let heading = name
  if (options.hasQuantityInHeading && quantity > 1) {
    heading = `${quantity} × ${name}`
  }

  return {
    id: product.id,
    price: totalPrice,
    priceBeforeDiscount: totalPriceBeforeDiscount,
    heading,
    image: imageUrl,
    quantity,
    type: product.productType,
  }
}

export function mapProductsWithQuantityToProductContents(
  productsWithQuantity: ProductWithQuantity[]
): ProductContent[] | [] {
  if (!productsWithQuantity.length) {
    return []
  }

  const productContents: ProductContent[] = productsWithQuantity.map(
    ({ id, quantity, price }): ProductContent => ({
      id,
      quantity,
      price,
    })
  )
  return productContents
}
