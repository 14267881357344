import { MouseEventHandler, ReactElement } from 'react'
import { Variants } from 'framer-motion'
import { ISODate } from 'lib/toISODateFormat'
import { Recipe } from '@mindfulchefuk/features/Recipes/interfaces'
import { ProductType, ProductWithQuantity } from '@mindfulchefuk/query'
import { LinkProps } from 'next/link'
import { ProductContent } from '@mindfulchefuk/features/Products/interfaces'

export enum CartItemType {
  RECIPE = 'recipe',
  PRODUCT = 'product',
}

export interface RecipeCartItem {
  id: string
  portions: number
  quantity: number
}

export interface RecipeCartState {
  allPortionSelectedRecipes: RecipeCartItem[]
  initialRecipes: RecipeCartItem[]
  lastAddedRecipeId: string
}

export interface ProductCartState {
  selectedProducts: ProductContent[]
  initialProducts: ProductContent[]
  lastAddedProductId: string
}

interface CartState {
  orderIsCompleted?: boolean
  basketTotal: number // The total price of all recipes in the legacy basket reducer
  deliveryDate: ISODate | Date
  maxRecipes: number
  minRecipes: number
  paymentDate: ISODate | Date
  portionCountPerMeal: number
  products: ProductWithQuantity[]
  recipes: Recipe[]
  shopOrderTotal: number
}

export interface CartDrawerState {
  isOpen: boolean
}

export interface CartNavigation extends CartState {
  children?: ReactElement | ReactElement[]
  onClick: () => void
}

export interface CartItem {
  hideImagesOnMobile?: boolean
  price?: number
  priceBeforeDiscount?: number
  heading: string
  id: string
  image: string
  quantity?: number
  children?: ReactElement
  as?: string | ReactElement
  isDisabled?: boolean
  url?: LinkProps['href']
  onClick?: MouseEventHandler
  feedback?: {
    id: string
    rating: number
  }
  type?: ProductType

  //Animation properties
  custom?: { index: number }
  layout?: boolean
  variants?: Variants
  initial?: string
  animate?: string
  exit?: string
}
