import React, { FunctionComponent, ReactElement } from 'react'
import styled from '@emotion/styled'
import { Variants, motion } from 'framer-motion'

const SectionContainer = styled(motion.section)`
  margin-bottom: 34px;
  will-change: opacity, transform;
`

export interface SectionProps {
  copy?: ReactElement
  children?: ReactElement | ReactElement[]
  initial?: string
  animate?: string
  variants?: Variants
  layout?: boolean
}

const sectionVariants = {
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
  show: {
    opacity: 1,
  },
}

export const Section: FunctionComponent<SectionProps> = ({
  copy,
  children,
  layout = true,
  initial = 'show',
  animate = 'show',
  variants = sectionVariants,
}) => {
  return (
    <SectionContainer
      animate={animate}
      exit="exit"
      initial={initial}
      layout={layout}
      variants={variants}
    >
      {copy && <motion.div layout>{copy}</motion.div>}
      {children}
    </SectionContainer>
  )
}
