import { isAfter, isSameDay } from 'date-fns'
import { ISODate } from '@mindfulchefuk/lib/dateFormats'

export const isSameDayOrAfter = (
  dateToCompare: ISODate,
  dateToCompareWith: ISODate
) => {
  return (
    isAfter(dateToCompare, dateToCompareWith) ||
    isSameDay(dateToCompare, dateToCompareWith)
  )
}
