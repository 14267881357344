import { Flex, Icon, ResetButton, Text } from '@mindfulchefuk/design-system'
import { motion, Variants } from 'framer-motion'
import React, { FunctionComponent } from 'react'

const badgeVariants: Variants = {
  normal: { scale: 1 },
  added: { scale: [1, 1.2, 1], transition: { duration: 0.3 } },
  tap: {
    scale: 0.9,
  },
}

interface CartButtonProps {
  newestItem?: boolean
  onClick: () => void
}

export const CartButton: FunctionComponent<CartButtonProps> = ({
  children,
  newestItem,
  onClick,
}) => (
  <motion.div
    initial={false}
    animate={newestItem ? 'added' : 'normal'}
    whileTap="tap"
    variants={badgeVariants}
  >
    <Flex position="relative">
      <ResetButton
        data-testid="cart-navigation-no-of-items"
        onClick={onClick}
        display="flex"
      >
        <Icon type="cart" size={32} color="aubergine" />
        <Flex
          align="center"
          justify="center"
          size={28}
          bg="broccoli"
          color="salt"
          borderRadius="50%"
          position="absolute"
          z={2}
          top={-14}
          right={-14}
        >
          <Text
            display="inline-flex"
            variant="body-lg-500-caps"
            position="relative"
            top={-1}
          >
            {children}
          </Text>
        </Flex>
      </ResetButton>
    </Flex>
  </motion.div>
)
