import React, { VoidFunctionComponent } from 'react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { Icon } from '@mindfulchefuk/design-system'

const closeVariants = {
  hide: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    translateX: '-50%',
    translateY: '-50%',
    transition: { delay: 0.1, ease: 'easeOut' },
  },
  tap: {
    scale: 0.9,
  },
}

const Close = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: -4px;
  left: -10px;

  width: 23px;
  height: 21px;

  padding: 0;
  border: none;
  border-radius: 100%;
  outline: none;
  background-color: white;

  cursor: pointer;
  will-change: transform;
`

interface Props {
  onClick: () => void
}

export const CloseButton: VoidFunctionComponent<Props> = ({ onClick }) => (
  <Close
    data-testid="close-button"
    variants={closeVariants}
    whileTap="tap"
    onClick={(event) => {
      event.preventDefault()
      onClick()
    }}
  >
    <Icon type="roundel-cross" size={24} />
  </Close>
)
