import styled from '@emotion/styled'
import {
  Flex,
  Image,
  PriceText,
  Text,
  theme,
} from '@mindfulchefuk/design-system'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import React, { useEffect, useState, VFC } from 'react'
import { CartItem } from '@mindfulchefuk/features/Cart/interfaces'

interface CartFlagProps {
  item: CartItem
}

const variants: Variants = {
  show: { opacity: 1, y: 0, transition: { when: 'beforeChildren' } },
  hide: { opacity: 0, y: 100 },
  exit: {
    opacity: 0,
    transition: { when: 'afterChildren' },
  },
}

const Flag = styled(motion.div)`
  display: none;
  position: absolute;
  color: ${theme.colors['aubergine-800']};
  background-color: ${theme.colors.white};
  right: ${theme.space[56]};
  top: ${theme.space[-4]};
  box-shadow: ${theme.shadows.standard};
  width: ${theme.space[384]};
  z-index: ${theme.zIndices.notification};

  &:before {
    content: '';
    position: absolute;
    right: ${theme.space[-12]};
    top: ${theme.space[32]};
    width: 0;
    height: 0;

    border-style: solid;
    border-width: ${theme.space[6]};
    border-color: transparent transparent white white;
    z-index: 10;

    transform-origin: 0 0;
    transform: rotate(-135deg);

    box-shadow: ${theme.shadows.triangle};
  }

  ${theme.media.sm} {
    display: block;
  }
`

const CartFlagImage = styled(Image)`
  height: 100%;
  object-fit: cover;
  width: 96px;
  min-height: 76px;
`

export const CartFlag: VFC<CartFlagProps> = ({ item }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    let timeout: NodeJS.Timeout
    clearTimeout(timeout)

    if (item) {
      setIsVisible(true)
      timeout = setTimeout(() => setIsVisible(false), 2000)
    } else {
      setIsVisible(false)
    }

    return () => clearTimeout(timeout)
  }, [item])

  return (
    <AnimatePresence mode="wait">
      {isVisible && item && (
        <Flag
          variants={variants}
          initial="hide"
          animate="show"
          exit="exit"
          layout
          data-testid="cart-flag"
        >
          <Flex width={384}>
            <CartFlagImage src={item.image} alt="" width={120} height={95} />
            <Flex justify="space-between" flexGrow={1} p={14}>
              <Text variant="body-sm-500">{item.heading}</Text>
              <PriceText
                variant="body-sm"
                flexShrink={1}
                ml={32}
                price={item.price}
              />
            </Flex>
          </Flex>
        </Flag>
      )}
    </AnimatePresence>
  )
}
