import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

import {
  Product,
  ProductWithQuantity,
  useQueryAllProducts,
} from '@mindfulchefuk/query'
import { RootState } from '@mindfulchefuk/types/store'
import { ProductContent } from '@mindfulchefuk/features/Products/interfaces'

interface ProductCart {
  productsInCart: ProductWithQuantity[]
  totalProducts: number
  totalPrice: number
}

export const useProductCart = () => {
  const { data: allProducts } = useQueryAllProducts()
  return useSelector((state: RootState) =>
    selectProductCart(state, allProducts)
  )
}

const selectProductCart = createSelector(
  [
    (state: RootState) => state.productCart,
    (_ignore, allProducts: Product[]) => allProducts,
  ],
  (productCart, allProducts): ProductCart => {
    const { selectedProducts } = productCart

    const productsInCart = selectedProducts.reduce(
      (productsWithQuantity, selectedProduct) => {
        const productFromStore = allProducts?.find(
          (item) => item.id === selectedProduct.id
        )
        if (productFromStore) {
          productsWithQuantity.push({
            ...productFromStore,
            quantity: selectedProduct.quantity,
          })
        }
        return productsWithQuantity
      },
      []
    )

    const totalPrice = productsInCart.reduce(
      (sum: number, item: ProductContent) => sum + item.price * item.quantity,
      0
    )

    const totalProducts = selectedProducts.reduce(
      (sum, item) => sum + item.quantity,
      0
    )

    return {
      productsInCart,
      totalProducts,
      totalPrice,
    }
  }
)
