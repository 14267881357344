import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { theme } from '@mindfulchefuk/design-system/system'

export const Footer = styled(motion.footer)`
  margin: -24px 0;
  border-top: 1px solid ${theme.colors['pepper-50']};
  transform: scale(1);
  z-index: 30;
`

export const Article = styled(motion.article)`
  width: 100%;
  height: 100%;
  padding: 15px 24px 0 24px;
  overflow: visible;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 0;

  ${theme.media.sm} {
    padding-top: 25px;
  }
`

export const Divider = styled(motion.hr)`
  border: 0;
  height: 1px;
  background: ${theme.colors['pepper-50']};
  margin-top: ${theme.space[16]};
  margin-bottom: ${theme.space[32]};
`
