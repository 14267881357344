import { isSameDayOrAfter } from '@mindfulchefuk/utils/isSameDayOrAfter'
import { dateFormats } from '@mindfulchefuk/lib/dateFormats'

//shows if the feature is on
export function isSingleCheckout(): boolean {
  const today = dateFormats.iso(new Date())
  return isSameDayOrAfter(
    today,
    dateFormats.iso(process.env.NEXT_PUBLIC_SINGLE_CHECKOUT_LIVE_DATE)
  )
}

//shows if the feature is on and the delivery date is when we can deliver combined orders
export function isSingleCheckoutJourney(deliveryDate: string): boolean {
  const isSingleCheckoutOn = isSingleCheckout()

  const showSingleCheckoutView = isSameDayOrAfter(
    dateFormats.iso(deliveryDate),
    dateFormats.iso(process.env.NEXT_PUBLIC_SINGLE_CHECKOUT_FIRST_DELIVERY_DATE)
  )

  return isSingleCheckoutOn && showSingleCheckoutView
}
