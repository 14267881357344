import React, { FunctionComponent, ReactElement } from 'react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import {
  Image,
  Text,
  Flex,
  Grid,
  theme,
  Box,
  PriceText,
  ButtonLink,
} from '@mindfulchefuk/design-system'
import { CartItem as CartItemProps } from '@mindfulchefuk/features/Cart/interfaces'

export const CartItemList = styled(motion.ul)`
  margin: 0;
  padding: 0;
  list-style: none;
`

interface ContainerProps {
  as: string | ReactElement
}

const Container = styled.li<ContainerProps>`
  position: relative;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  margin-bottom: ${theme.space[12]};
  border-bottom: 1px solid ${theme.colors['aubergine-50']};

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
`

export const CartItem: FunctionComponent<CartItemProps> = ({
  hideImagesOnMobile,
  isDisabled,
  children,
  price,
  priceBeforeDiscount,
  heading,
  image,
  as = motion.li as unknown as ReactElement,
  id,
  onClick,
  ...rest
}) => (
  <Container data-testid="cart-product-item" as={as} id={id} {...rest}>
    <Grid
      gridTemplateColumns="88px 1fr"
      gap={10}
      pb={12}
      color="aubergine-50"
      position="relative"
      width="100%"
    >
      <Box
        role={onClick ? 'button' : undefined}
        cursor={onClick ? 'pointer' : undefined}
        onClick={onClick}
      >
        <Image src={image} alt="" width={88} height={88} useFallback />
      </Box>
      <Grid gridTemplateColumns="1fr" alignContent="space-between">
        {onClick ? (
          <ButtonLink
            variant="body-md"
            onClick={onClick}
            display="flex"
            mb={6}
            textAlign="left"
            textTransform="capitalize"
          >
            {heading}
          </ButtonLink>
        ) : (
          <Text
            as="h2"
            variant="body-md"
            color="pepper"
            mb={6}
            textTransform="capitalize"
          >
            {heading}
          </Text>
        )}
        <Flex direction="row" wrap="wrap">
          <Box pt={8}>{children}</Box>
          <Box ml="auto" pt={8}>
            {priceBeforeDiscount && (
              <PriceText
                price={priceBeforeDiscount}
                variant="body-md-500"
                data-testid="cart-product-item-discount"
                as="span"
                isDiscounted
                mr={8}
              />
            )}

            <PriceText price={price} variant="body-md-500" />
          </Box>
        </Flex>
      </Grid>
    </Grid>
  </Container>
)
