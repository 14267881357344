import { useRouter } from 'next/router'

export const useDeliveryDate = () => {
  const router = useRouter()
  const deliveryDate = Array.isArray(router.query.deliveryDate)
    ? router.query.deliveryDate[0]
    : router.query.deliveryDate

  if (!deliveryDate) return null

  // Dirty fix for a weird bug where e.g. ?page=recipes is getting appended to the delivery date from a redirect somewhere.
  // This should be a temporary fix until we can figure out where the bug is coming from.
  // Is this a Next.js bug? This should never happen!
  return deliveryDate.split('?')[0]
}
