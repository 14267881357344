import React, { VoidFunctionComponent } from 'react'
import { Box, Image, Flex, Text } from '@mindfulchefuk/design-system'

export const EmptyCart: VoidFunctionComponent = () => (
  <Flex
    margin="0 auto"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    maxWidth={384}
    height="100%"
    mt={-24}
    data-testid="empty-cart"
  >
    <Text as="p" variant="body-md" textAlign="center">
      There’s nothing in here just yet, but don’t worry - we have plenty of
      healthy options for you to choose from!
    </Text>
    <Box mt={{ base: 32, sm: 64 }} maxWidth="100%">
      <Image
        src="illustrations/mindful-chef-got-it-character.png"
        alt="Basket empty"
        width={300}
        loading="eager"
        resolve
      />
    </Box>
  </Flex>
)
