import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import { motion, Variants } from 'framer-motion'
import { theme } from '@mindfulchefuk/design-system/system'

const containerVariants: Variants = {
  hide: {
    opacity: 0,
    x: 100,
    transition: {
      duration: 0.1,
      when: 'beforeChildren',
    },
    transitionEnd: {
      pointerEvents: 'none',
    },
  },
  initial: {
    opacity: 0,
    x: 100,
  },
  show: {
    opacity: 1,
    x: 0,
    transition: {
      ease: 'easeOut',
      duration: 0.2,
      delay: 0.1,
      when: 'beforeChildren',
    },
    transitionEnd: {
      pointerEvents: 'all',
    },
  },
}

interface ContainerProps {
  variants: Variants
}
const Container = styled(motion.aside)<ContainerProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 24px;
  background-color: ${theme.colors.white};
  z-index: ${theme.zIndices.drawer};
  will-change: transform, opacity;

  ${theme.media.sm} {
    max-width: 512px;
  }
`

const CloseOverlay = styled.div`
  display: none;

  ${theme.media.sm} {
    display: block;
    right: 512px;
    width: 200vw;
    height: 200vh;
    position: fixed;
    top: -100vh;
    transform: none;
    cursor: pointer;
    will-change: transform opacity;
    background-color: ${theme.colors['pepper-600']};
    opacity: 0.5;
  }
`

export const CloseButton = styled(motion.button)`
  border: none;
  outline: none;
  width: 31px;
  height: 31px;
  padding: 0;
  margin: 0;
  margin-right: -8px;
  background: white;
  border-radius: 100%;
  cursor: pointer;
  will-change: transform;
  z-index: 10;
`

type TCartDrawerWrapper = {
  children: ReactNode
  isOpen: boolean
  onClose: () => void
}

export const CartDrawerWrapper = ({
  isOpen,
  children,
  onClose,
}: TCartDrawerWrapper) => (
  <Container
    variants={containerVariants}
    initial="initial"
    animate={isOpen ? 'show' : 'hide'}
    data-testid="cart-drawer-wrapper"
  >
    <CloseOverlay onClick={onClose} />
    {children}
  </Container>
)
