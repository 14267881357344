import { useCallback, useEffect, useState } from 'react'
import { displayDixaMessenger } from '@mindfulchefuk/features/Dixa/displayDixaMessenger'

export const useCartDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const toggleDrawerOpen = useCallback(
    () => setIsDrawerOpen((current) => !current),
    []
  )

  useEffect(() => {
    const body = document.querySelector('body')
    body.style.overflow = isDrawerOpen ? 'hidden' : ''
    displayDixaMessenger(!isDrawerOpen)

    return () => {
      body.style.overflow = ''
    }
  }, [isDrawerOpen])

  return { isDrawerOpen, toggleDrawerOpen }
}
