import React, { VoidFunctionComponent } from 'react'
import styled from '@emotion/styled'
import { AnimatePresence } from 'framer-motion'

import { CartItem as CartItemProps } from '@mindfulchefuk/features/Cart/interfaces'
import { CloseButton } from '@mindfulchefuk/features/Cart/components/CartDrawer/CloseButton'
import {
  CartItem,
  CartItemList,
} from '@mindfulchefuk/features/Cart/components/CartItem/CartItem'
import { Flex, Icon, Text } from '@mindfulchefuk/design-system'
import { Roundel } from '@mindfulchefuk/design-system/Roundel/Roundel'
import { isSingleCheckoutJourney } from '@mindfulchefuk/utils/isSingleCheckout'
import { useDeliveryDate } from '@mindfulchefuk/features/Delivery/hooks/useDeliveryDate'

type HiddenButtons = {
  showButtons?: false
  removeAllItem?: (id: string, quantity: number) => void
  removeItem?: (id: string) => void
  addItem?: (id: string) => void
}

type ShownButtons = {
  showButtons: true
  removeAllItem?: (id: string, quantity: number) => void
  removeItem: (id: string) => void
  addItem: (id: string) => void
}

// if showButtons prop is passed ensure all event handlers are passed also
type Props = {
  hideImagesOnMobile?: boolean
  animationDelay?: number
  isOpen: boolean
  items: CartItemProps[]
  canIncrement?: boolean
} & (HiddenButtons | ShownButtons)

interface VariantProps {
  index: number
}

const ANIMATION_STAGGER_VALUE = 0.2
const ANIMATION_INITIAL_VALUE = 0.5
const itemVariants = {
  hide: { opacity: 0, x: 100 },
  show: (custom: VariantProps) => {
    const { index } = custom
    return {
      opacity: 1,
      x: 0,
      transition: {
        delay: ANIMATION_INITIAL_VALUE + index * ANIMATION_STAGGER_VALUE,
        duration: 0.2,
        ease: 'easeInOut',
        when: 'beforeChildren',
      },
    }
  },
  exit: { opacity: 0, x: 100 },
}

const ControlButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

export const ItemList: VoidFunctionComponent<Props> = ({
  hideImagesOnMobile,
  animationDelay = 0,
  showButtons = false,
  isOpen,
  items,
  removeAllItem,
  removeItem,
  addItem,
  canIncrement = true,
}) => {
  const deliveryDate = useDeliveryDate()
  const singleCheckoutNotEnabled = !isSingleCheckoutJourney(deliveryDate)
  return (
    <CartItemList>
      <AnimatePresence>
        {items.map(({ id, heading, price, image, quantity }, index) => (
          <CartItem
            hideImagesOnMobile={hideImagesOnMobile}
            layout
            animate={isOpen ? 'show' : 'hide'}
            custom={{ index: animationDelay + index }}
            exit="hide"
            initial="hide"
            variants={itemVariants}
            key={id}
            id={id}
            heading={heading}
            price={price}
            image={image}
          >
            <>
              {showButtons && (
                <>
                  {singleCheckoutNotEnabled && (
                    <CloseButton onClick={() => removeAllItem(id, quantity)} />
                  )}

                  <Flex
                    alignItems="center"
                    width="90px"
                    justifyContent="space-between"
                  >
                    <ControlButton
                      data-testid="decrement-button"
                      onClick={() => removeItem(id)}
                    >
                      <Roundel filled size={28}>
                        <Icon type="minus" size={12} />
                      </Roundel>
                    </ControlButton>
                    <Text
                      color="aubergine"
                      variant="body-lg-500"
                      data-testid="recipe-quantity"
                    >
                      {quantity}
                    </Text>
                    <ControlButton
                      data-testid="increment-button"
                      onClick={() => addItem(id)}
                      disabled={!canIncrement}
                    >
                      <Roundel filled size={28}>
                        <Icon type="plus" size={12} />
                      </Roundel>
                    </ControlButton>
                  </Flex>
                </>
              )}
            </>
          </CartItem>
        ))}
      </AnimatePresence>
    </CartItemList>
  )
}
